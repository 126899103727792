import React, { useContext, useEffect, useState } from 'react'
import { useAppContext } from '../../hooks/useAppContext';
import { PageTitle, Subtitle1 } from '../../brand/typographies';
import { StorSelfButton } from '../../components/buttons/StorSelfButton';
import { PaymentsContext } from '../../providers/payments-provider/PaymentsProvider';
import { Box, Skeleton } from '@mui/material';
import { AccountContext } from '../../providers/account-provider/AccountProvider';
import { UnitsCard } from '../../components/UnitsCard';

export const ExternalPaymentForm = () => {
    const appContext = useAppContext();
    const { state: {
        ledger, loading
    }, flowTitle } = useContext(PaymentsContext);

    const loaded = ledger && !loading;

    const { refetchLedgers } = useContext(AccountContext)

    const [hasOpened, setHasOpened] = useState(false);

    const openPaymentUrl = () => {
        if (ledger?.paymentUrl) {
            const newWindow = window.open(ledger.paymentUrl, '_blank');
            if (newWindow) newWindow.focus();
            setHasOpened(true);
        }
    };

    if (hasOpened) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <PageTitle>{flowTitle}</PageTitle>
                <Subtitle1 center>
                    Your payment form has opened in a new tab. Please click the below button to sync after the payment is complete
                </Subtitle1>

                <UnitsCard
                    loading={!loaded}
                    facility={appContext.selectedFacility}
                    ledgers={ledger ? [ledger] : []}
                    phoneNumber={appContext.phoneNumber}
                />

                <StorSelfButton onClick={refetchLedgers}>Sync</StorSelfButton>
            </Box>
        )
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <PageTitle>{flowTitle}</PageTitle>
            <Subtitle1 loading={!loaded} center>
                Your facility uses an external payment form. Please press the button below to open the payment form.
            </Subtitle1>

            <UnitsCard
                loading={!loaded}
                facility={appContext.selectedFacility}
                ledgers={ledger ? [ledger] : []}
                phoneNumber={appContext.phoneNumber}
            />

            {loaded
                ? <StorSelfButton onClick={openPaymentUrl}>Open Payment Form</StorSelfButton>
                : <Skeleton />
            }

        </Box>
    )
}
