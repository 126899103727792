import { AccessPoint } from "../../providers/account-provider/AccountProvider";
import { IAccessPoint } from "../../types/IAccessPoint";
import { IFacility } from "../../types/IFacility";
import { ILedger } from "../../types/ILedger";
import { ILock } from "../../types/ILock";

const currentDate = new Date();
const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
const firstDayOfNextMonth = ('0' + (nextMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + nextMonth.getDate()).slice(-2) + '-' + nextMonth.getFullYear();

export default {
    facility: {
        id: "1",
        name: "Facility 1",
        street: "123 Main St",
        city: "Raleigh",
        state: "NC",
        postal: "12345",
        phone: "123-456-7890",
        email: "",
        websiteUrl: "",
        tenantReviewUrl: "",
        latitude: 1,
        longitude: 1,
        companyConfigId: 1
    },
    ledger: {
        id: "1",
        facilityId: "1",
        invoiceDayOfMonth: 1,
        rate: 55,
        active: true,
        paidThrough: firstDayOfNextMonth,
        balance: 0,
        nextChargeDate: firstDayOfNextMonth,
        nextChargeAmount: 55,
        currentPrepayBalance: 0,
        delinquent: false,
        facilityUnit: {
            unitName: "A1",
            balance: 0,
            rate: 55
        },
        tenantId: "1"
    },
    accessPoints: [{ name: "Entry Gate", id: "1" }, { name: "Climate Control A", id: "2" }],
    lock: {
        serialCode: "XYY4KLW6",
        unitNumber: "A1",
        unlockCode: "9273",
    }
} as { facility: IFacility, ledger: ILedger, accessPoints: AccessPoint[], lock: ILock };
