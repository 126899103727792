import { FacilitySelect } from "../pages";
import { useContext, useEffect } from "react";
import { PopoverContext } from "../providers/PopoverContextProvider";
import { useLocation } from "react-router-dom";
import { FacilitiesContext } from "../providers/facility-provider/FacilitiesContext";
import { useFacilityIdParam } from "../providers/facility-provider/[hooks]/useFacilityIdParam";

// TODO: fix- this hook is an anti pattern.
export const useSelectFacility = (showHeader = true, props: any = {}) => {
    const { onShowPopover, onClosePopover } = useContext(PopoverContext);
    const { pathname } = useLocation();
    const isLandingPage = pathname === "/";
    const { facilities, setSelectedFacility, closestFacility } = useContext(FacilitiesContext);

    const showSelectFacility = () => {
        onShowPopover(
            <FacilitySelect
                onContinue={onClosePopover}
                facilities={facilities}
                closestFacility={closestFacility}
                setSelectedFacility={setSelectedFacility}
                {...props} />,
            {
                showHeader: true, //!isLandingPage && showHeader,
                light: true,
            }
        )
    }

    return { showSelectFacility }
};

export const useForceSelectFacility = (showHeader = true, props: any = {}) => {
    const { showSelectFacility } = useSelectFacility();
    const [facilityId] = useFacilityIdParam()

    useEffect(() => {
        if (!facilityId) showSelectFacility()
    }, [facilityId])
};

