import React, { useContext, useEffect, useState, } from "react";

import { Body2, H4, PageTitle, Subtitle1 } from "../../../brand/typographies";
import { FacilitiesContext } from "../../../providers/facility-provider/FacilitiesContext";
import { useListenToFacilityChange } from "../../../hooks/useListenToFacilityChange";
import { UnitsCard } from "../../../components/UnitsCard";
import { usePaymentNavigation } from "../[hooks]/usePaymentNavigation";
import { LedgerEligible, PaymentsContext } from "../../../providers/payments-provider/PaymentsProvider";
import { LookupAnotherAccount } from "./LookupAnotherAccount";
import { Section } from "../../../components/layout/Section";
import Select from "../../../components/Select";
import { StorSelfButton } from "../../../components/buttons/StorSelfButton";
import { useAppContext } from "../../../hooks/useAppContext";
import { Skeleton } from "@mui/material";
import { usePrePayMonthsAllowed } from "../../../hooks/usePrePayMonthsAllowed";

const MascotConfused = React.lazy(() => import('../../../components/icons/MascotConfused'));

enum PaymentOption {
    "Pay the current balance",
    "Pay balance plus one month",
    "Pay balance plus two months",
    "Pay balance plus three months",
    "Pay balance plus four months",
    "Pay balance plus five months",
    "Pay balance plus six months",
}

const paymentOptions: string[] = Object.values(PaymentOption).filter(ls => typeof ls === "string") as string[]

export function PaymentAmountPage() {
    const appContext = useAppContext();
    const { state: {
        ledger,
        loading,
        phoneNumber,
        ledgerEligible,
        prePayMonths,
        paymentAmount,
        fetchingPaymentAmount
    }, flowTitle, setPrePayMonths } = useContext(PaymentsContext);

    useListenToFacilityChange(PaymentsContext);
    const navigateTo = usePaymentNavigation();
    const { selectedFacility } = useContext(FacilitiesContext)

    const { allowedPrePayMonths, pendingPrePayMonths } = usePrePayMonthsAllowed();
    const loaded = !!(ledger && selectedFacility)

    const totaldue = paymentAmount || ledger?.balance || 0

    if (ledgerEligible === LedgerEligible.Eligible) {
        return (
            <>
                <PageTitle>{flowTitle}</PageTitle>

                <Subtitle1 sx={{ my: 1 }} center>
                    Choose an amount to pay on this unit.
                </Subtitle1>

                <UnitsCard
                    loading={!loaded || loading}
                    facility={selectedFacility}
                    ledgers={ledger ? [ledger] : []}
                    phoneNumber={phoneNumber}
                />

                <Section sx={{
                    my: 4,
                }}>
                    <Body2 center bold>
                        Total Due
                        <H4 loading={fetchingPaymentAmount} bold>
                            {"$" + (totaldue || ledger?.balance || 0).toFixed(2)}
                        </H4>
                        <Body2 error={ledger?.delinquent} sx={{ fontStyle: "italic" }}>Paid Through: {ledger?.paidThrough}</Body2>
                    </Body2>
                </Section>

                <Section sx={{
                    my: 4,
                }}>
                    {pendingPrePayMonths
                        ? <Skeleton width="100%" height="200px" />
                        : allowedPrePayMonths > 0
                            ? <Select
                                label="Please select amount to pay*"
                                options={paymentOptions}
                                value={PaymentOption[prePayMonths || 0]}
                                onChange={(val) => {
                                    setPrePayMonths(PaymentOption[val as keyof typeof PaymentOption], 'replaceIn')
                                }}
                            />
                            : null
                    }
                </Section>

                <StorSelfButton
                    analyticsTag="Continue"
                    onClick={() => {
                        if (phoneNumber != appContext.phoneNumber) {
                            navigateTo.newPaymentMethod()
                        } else {
                            navigateTo.paymentMethod()
                        }
                    }}
                    disabled={totaldue <= 0}
                >Continue</StorSelfButton>


                <LookupAnotherAccount navigateToLookup={navigateTo.lookup} />
            </>
        );
    }

    return (
        <>
            <PageTitle>{flowTitle}</PageTitle>
            <Subtitle1 center>
                You are unable to make a payment in your current delinquent state, please contact the facility for more information.
            </Subtitle1>

            <React.Suspense fallback={null}>
                <MascotConfused />
            </React.Suspense>
        </>
    )

};
